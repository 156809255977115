import { useUser } from "@auth0/nextjs-auth0/client";
import { useEffect, useState } from "react";
import { FaRegUser } from "react-icons/fa";
import { FiShoppingCart } from "react-icons/fi";
import { IoMdClose, IoMdMenu } from "react-icons/io";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { NavigationItemWithSubNav } from "~/external/prismic.types";
import useCartItemsCount from "~/hooks/commercetools/useCartItemsCount";
import useNavigation from "~/hooks/useNavigation";
import useRouterEvent from "~/hooks/useRouterEvent";
import Dropdown, { DropdownImage, DropdownMenu } from "./components/Dropdown";
import DropdownMenuLink from "./components/DropdownMenuLink";
import DropdownMenuTitle from "./components/DropdownMenuTitle";
import LogoLink, { LogoWrapper } from "./components/LogoLink";
import Menu from "./components/Menu";
import MenuLink, {
  AWrapper,
  BadgeWrapper,
  LabelWrapper,
  MenuLinkWrapper,
} from "./components/MenuLink";
import MobileMenu, {
  MobileSubMenu,
  MobileSubMenuLink,
  MobileSubMenuLinks,
  MobileSubMenuTitle,
} from "./components/MobileMenu";
import Ribbon from "./components/Ribbon";
import TopBar, { TopBarWrapper } from "./components/TopBar";
import useDropdownImage from "./hooks/useDropdownImage";

const MainNavWrapper = styled.div<{ backgroundColor: string }>`
  /*
  background-color: ${(props) => props.backgroundColor || "transparent"};
  transition: background-color 0s;
  transition-delay: 0.2s;
  */

  /* when menu is hovered or forced light */
  &:hover,
  &.force-light-background {
    ${TopBarWrapper} {
      background-color: ${(p) => p.theme.colors.light};
      transition-delay: 0s;
    }
    ${LogoWrapper} {
      fill: ${(p) => p.theme.colors.dark};
      transition-delay: 0s;
    }
    ${MenuLinkWrapper} {
      ${AWrapper} {
        transition-delay: 0s;
        ${LabelWrapper} {
          color: ${(p) => p.theme.colors.dark};
          transition-delay: 0s;
          &:after {
            /* -- the line -- */
            background-color: ${(p) => p.theme.colors.dark};
            transition-delay: 0s;
          }
        }
        ${BadgeWrapper} {
          transition-delay: 0s;
          background-color: ${(p) => p.theme.colors.dark};
          color: ${(p) => p.theme.colors.light};
        }
      }
    }
  }

  /*
  .route-change & {
    ${TopBarWrapper} {
      background-color: transparent !important;
    }
  }
  */
`;

function DropdownMenuWrapperDesktop(props) {
  // const [dropdownImage, setDropdownImage] = useState();

  const { dropdownImage, hoverEvents } = useDropdownImage(true);

  const pinnedItem = props.items.find((item) => item.pin);

  const [hoveredItem, setHoveredItem] = useState(-1);

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTitle>{props.label}</DropdownMenuTitle>
        {props.items.map((item, i) => (
          <DropdownMenuLink
            key={i}
            label={item.label}
            href={item.href}
            as={item.href_as}
            nolink={!item.href}
            pin={item.pin && hoveredItem == -1}
            onHoverStart={() => {
              hoverEvents(item.image.url).onHoverStart();
              setHoveredItem(i);
            }}
            onHoverEnd={() => {
              hoverEvents(item.image.url).onHoverEnd();
              setHoveredItem(-1);
            }}
            onClick={() => {
              hoverEvents(item.image.url).onHoverEnd();
              setHoveredItem(-1);
            }}
          />
        ))}
      </DropdownMenu>
      {props.hasImages && <DropdownImage pinnedItem={pinnedItem} src={dropdownImage} />}
    </>
  );
}

type Props = {
  backgroundColor: string;
  color: string;
};

export default function MainNav({ color, backgroundColor }: Props) {
  // get colors from state, if there
  const ui = useSelector((state) => state.ui);
  if (ui.topBarBackgroundColor) backgroundColor = ui.topBarBackgroundColor;
  if (ui.topBarColor) color = ui.topBarColor;

  const nav = useNavigation();
  const { user, isLoading } = useUser();

  const [activeMobileMenu, setActiveMobileMenu] = useState<
    NavigationItemWithSubNav | undefined
  >(undefined);

  // get cartItems
  const cart = useSelector((state) => state.cart);
  const cartItemsCount = useCartItemsCount(cart);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const ribbonActive = nav?.mainDesktop.ribbon.active;

  useRouterEvent("routeChangeStart", () => setMobileMenuOpen(false));

  useEffect(() => {
    if (nav?.mainMobile.items) {
      setActiveMobileMenu(nav?.mainMobile.items[0]);
    }
  }, [nav, setActiveMobileMenu]);

  return (
    <MainNavWrapper
      backgroundColor={backgroundColor}
      className={mobileMenuOpen ? "force-light-background" : ""}
      style={{ marginTop: ribbonActive ? "4.675rem" : undefined }}
    >
      <Ribbon ribbon={nav?.mainDesktop.ribbon} />
      <TopBar
        top={ribbonActive ? "4.675rem" : "0"}
        backgroundColor={backgroundColor}
        color={color}
      >
        <LogoLink color={color} onTap={() => setMobileMenuOpen(false)} />
        <Menu color={color} backgroundColor={backgroundColor} hiddenOnSmall>
          {nav?.mainDesktop.items.map((menu) => (
            <MenuLink
              key={menu.label}
              label={menu.label}
              href={menu.href}
              as={menu.href_as}
              nolink={!menu.href}
            >
              {menu.subNavs && (
                <Dropdown top={ribbonActive && "9.675rem"}>
                  {menu.subNavs.map((subNav) => (
                    <DropdownMenuWrapperDesktop key={subNav.label} {...subNav} />
                  ))}
                </Dropdown>
              )}
            </MenuLink>
          ))}
        </Menu>
        <Menu justify="flex-end" flex="auto">
          {!isLoading && !user && (
            <MenuLink label="Membership" href="/membership" hiddenOnSmall />
          )}
          {!isLoading && !user && (
            <MenuLink href="/api/auth/login" icon={<FaRegUser />} />
          )}
          {user && <MenuLink href="/account" icon={<FaRegUser />} />}
          <MenuLink
            href="/cart"
            badge={cartItemsCount || 0}
            icon={<FiShoppingCart />}
          />
          <MenuLink
            icon={mobileMenuOpen ? <IoMdClose /> : <IoMdMenu />}
            onClick={() =>
              mobileMenuOpen ? setMobileMenuOpen(false) : setMobileMenuOpen(true)
            }
            hiddenOnBig
          />
        </Menu>
      </TopBar>
      <MobileMenu
        mobileMenuOpen={mobileMenuOpen}
        mobileMenuClose={() => setMobileMenuOpen(false)}
        style={{
          paddingTop: ribbonActive && mobileMenuOpen ? "9.625rem" : undefined,
        }}
      >
        <Menu verticalScroll>
          {nav?.mainMobile.items.map((menu, i) => (
            <MenuLink
              key={menu.label}
              label={menu.label}
              href={menu.href}
              as={menu.href_as}
              nolink={!menu.href}
              inline
              active={activeMobileMenu && activeMobileMenu.label === menu.label}
            />
          ))}
        </Menu>
        {activeMobileMenu && (
          <>
            {activeMobileMenu &&
              activeMobileMenu.subNavs.map((menu, i) => (
                <MobileSubMenu key={menu.label}>
                  <MobileSubMenuTitle>{menu.label}</MobileSubMenuTitle>
                  <MobileSubMenuLinks cols={2}>
                    {menu.items.map((item) => (
                      <MobileSubMenuLink
                        key={item.label}
                        label={item.label}
                        href={item.href}
                        as={item.href_as}
                        img={item.image?.url}
                      />
                    ))}
                  </MobileSubMenuLinks>
                </MobileSubMenu>
              ))}
          </>
        )}
      </MobileMenu>
    </MainNavWrapper>
  );
}
