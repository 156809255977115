import classnames from "classnames";
import { useRouter } from "next/router";
import styled from "styled-components";
import useChildrenWithProps from "~/hooks/useChildrenWithProps";
import { smallerThan } from "~/styles/theme";
import { MenuLinkWrapper } from "./MenuLink";

const MenuWrapper = styled.div`
  flex: ${(p) => p.flex || "auto"};
  justify-content: ${(p) => p.justify || "flex-start"};
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  color: ${(p) => p.color || "black"};
  height: 100%;
  /* hide on smaller screens */
  ${(p) => smallerThan(p.theme.breakpoints.ipadLandscape)} {
    display: ${(p) => (p.hideOnSmall ? "none" : "flex")};
  }

  &.vertical-scroll {
    height: auto;
    display: block;
    ${MenuLinkWrapper} {
      display: inline-flex;
      margin-right: ${(p) => p.theme.sizes.hSpacingSmall * 2}rem;
    }
    /* allow scroll */
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.hidden {
    opacity: 0;
  }
`;

function Menu(props) {
  const {
    color = undefined,
    backgroundColor = undefined,
    justify = "flex-start",
    flex = "auto",
    hiddenOnSmall = undefined,
    hiddenOnBig = undefined,
    verticalScroll = false,
  } = props;

  // only pass defined props to children...
  const childrenProps = {};
  if (color) childrenProps.color = color;
  if (backgroundColor) childrenProps.backgroundColor = backgroundColor;
  if (hiddenOnSmall) childrenProps.hiddenOnSmall = hiddenOnSmall;
  if (hiddenOnBig) childrenProps.hiddenOnBig = hiddenOnBig;

  //
  const { asPath } = useRouter();

  const children = useChildrenWithProps(props.children, childrenProps);

  return (
    <MenuWrapper
      justify={justify}
      flex={flex}
      className={classnames({
        "vertical-scroll": verticalScroll,
        "hidden": asPath.includes("/checkout/"),
      })}
    >
      {children}
    </MenuWrapper>
  );
}

export default Menu;
