import { CSSProperties, useRef, useState } from "react";
import styled from "styled-components";
import Input from "~/styles/elements/Input";
import Label from "~/styles/elements/Label";
import TextArea from "~/styles/elements/TextArea";

const Wrapper = styled.div<any>`
  position: relative;
  display: ${(p) => (p.inline ? "inline-block" : "block")};
  /*margin-bottom: 0.8rem;*/
  border: 0;
  padding: 0;
  text-align: left;
  /*border-bottom: 1px solid #ccc;*/
  /*background-color: transparent;*/
`;

const ScrollToTarget = styled.div`
  position: absolute;
  top: -120px;
  width: 10px;
  height: 10px;
  pointer-events: none;
  /* background-color: red; */
`;

function FormInputText(props) {
  const {
    label = "",
    value,
    type = "text",
    name,
    inline = false,
    focus = false,
    configuration = {},
    pointer = false,
    patternErrorMessage,
    after,
    showError = true,
    ...restOfProps
  } = props;

  // NOTE: name attribute is currently used for id as well.

  const [liftLabel, setLiftLabel] = useState(false);
  const [hadFocus, setHadFocus] = useState(false);
  const [changed, setChanged] = useState(false);

  // TODO: Needed for performance?
  // useEffect(() => {}, [liftLabel]);

  const focusedLabel: CSSProperties = {
    position: "relative",
    left: "0",
    width: "100%",
    top: type === "textarea" ? (props.borderBottomOnly ? "0px" : "34px") : "24px",
    color: "#aaa",
    transition: "0.2s",
    letterSpacing: "0.5px",
    fontSize: "10px",
    marginTop: "-16px",
    marginLeft: "0.7em",
    pointerEvents: "none",
    // zIndex: 1
  };

  const defocusedLabel: CSSProperties = {
    position: "relative",
    top: type === "textarea" ? (props.borderBottomOnly ? "24px" : "44px") : "36px",
    left: "0",
    transition: "0.2s",
    marginTop: "-16px",
    marginLeft: "0.4em",
    // zIndex: -1,
    cursor: pointer ? "pointer" : "text",
    pointerEvents: "none",
  };

  const shouldLiftLabel = liftLabel || value !== "";

  const required = props.required || !configuration.optional;
  const optional = props.optional || configuration.optional;
  const pattern = props.pattern || configuration.validation;

  const Component = type === "textarea" ? TextArea : Input;

  const ref = useRef<HTMLInputElement>();
  const [hasError, setHasError] = useState(false);

  const checkValidity = () => {
    if (!ref.current?.checkValidity()) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  };

  console.log("hasError", hasError);

  return (
    <Wrapper inline={inline}>
      <ScrollToTarget className="scrollToTarget" />
      <Label htmlFor={name} style={shouldLiftLabel ? focusedLabel : defocusedLabel}>
        {label}
        {optional && <span> (optional)</span>}
      </Label>
      <Component
        ref={ref}
        style={{
          height: "60px",
          backgroundColor: "transparent",
          cursor: pointer
            ? "pointer"
            : props.readOnly || props.disabled
            ? "no-drop"
            : "initial",
        }}
        value={value}
        type={type}
        id={name}
        name={name}
        required={!optional && required}
        pattern={pattern}
        {...restOfProps}
        hadFocus={hadFocus}
        autoFocus={focus}
        className={props.isInvalid && !changed && "is-invalid"}
        onFocus={() => {
          setLiftLabel(true);
          if (!hadFocus) setHadFocus(true);
        }}
        onBlur={() => {
          setLiftLabel(false);
          checkValidity();
        }}
        onChange={(e) => {
          setChanged(true);
          props.onChange && props.onChange(e);
        }}
        onKeyUp={(e) => {
          setChanged(true);
          setHasError(false);
          props.onKeyUp && props.onKeyUp(e);
        }}
        // onInvalid={e => console.log('invalid', e)}
      />
      {showError && hasError ? (
        <div
          style={{
            margin: "0.4rem 0 0 0.4rem",
            fontSize: "smaller",
            color: "#f44336",
          }}
        >
          {patternErrorMessage || ref.current?.validationMessage || "Invalid input"}
        </div>
      ) : (
        after
      )}

      {/* TODO: Errors */}
    </Wrapper>
  );
}

export default FormInputText;
